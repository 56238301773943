<!--
    Created by 程雨喵'mac on 2023/12/28.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：编辑
    功能概述：编辑基本信息/运维人员
-->
<style lang="less">
.location-submit-dialog {
  padding-bottom: @containerGap;
  height: 100%;
  .submit-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    .submit-inner-box {
      flex: 1;
      background: @backColorStrong;
      border-radius: @borderRadiusMid;
      overflow: auto;
    }
  }
}
</style>

<template>
  <yg-drawer
    width="700"
    title="编辑点位"
    :back-type="2"
    sure-text="提交"
    :sure-button-disabled="buttonDisabled"
    :sure-button-disabled-text="buttonDisabledMsg"
    @on-sure="onSure"
    @on-close="onClose"
  >
    <div
      slot="content"
      class="location-submit-dialog"
    >
      <div class="submit-inner">
        <div class="submit-inner-box">
          <yg-tab-primary
            :index="tab.index"
            :items="tab.items"
            :permission-items="tab.permissionItems"
            @on-change="onTabChanged"
          />
          <div class="padding-primary">
            <v-base-info
              v-if="tab.index === 0"
              :model="model"
              @on-change="onChangeDisabled"
            />
            <v-contact
              v-else-if="tab.index === 1"
              :model="model"
              @on-change="onChangeDisabled"
            />
            <v-setting
              v-else-if="tab.index === 2"
              :model="model"
              @on-change="onChangeDisabled"
            />
          </div>
        </div>
      </div>
    </div>
  </yg-drawer>
</template>

<script>
import LocationApiSet from '@/request/api/LocationApiSet';
import vBaseInfo from './baseInfo.vue';
import vContact from './contact.vue';
import vSetting from './setting.vue';

export default {
  components: { vContact, vBaseInfo, vSetting },
  props: {
    index: {
      type: Number,
      default: 0
    },
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      tab: {
        index: 0,
        items: ['基本信息', '运维人员信息', '运营设置'],
        permissionItems: ['system:site:edit', 'system:site:manager:edit', 'system:site:opration:set']
      },
      buttonDisabled: true,
      buttonDisabledMsg: '',
      needRefreshList: false
    };
  },
  created () {
    this.tab.index = this.index;
  },
  methods: {
    onSure () {
      console.log(this.model);
      let res = {};
      const tIndex = this.tab.index;
      if (tIndex === 0) {
        // 基本信息编辑
        res = this.model.checkValidInfoSubmit();
      } else if (tIndex === 1) {
        // 运维人员编辑
        res = this.model.checkValidConcatSubmit();
      } else if (tIndex === 2) {
        // 运营设置
        res = this.model.checkValidOprationSubmit();
      }
      if (!res.success) {
        this.$Message.warning(res.msg);
      } else {
        this.requestSubmit(tIndex, res.params);
      }
    },
    onClose () {
      this.$emit('on-close', this.needRefreshList);
    },
    onChangeDisabled ({ disabled, msg }) {
      this.buttonDisabled = disabled;
      this.buttonDisabledMsg = msg;
    },
    onTabChanged (e) {
      if (e === this.tab.index) return;
      this.buttonDisabled = false;
      this.buttonDisabledMsg = '';
      this.tab.index = e;
    },
    requestSubmit (type, params) {
      let api = {};
      if (type === 0) {
        // 基本信息
        api = LocationApiSet.editSiteAddressApi;
      } else if (type === 1) {
        // 运维人员
        api = LocationApiSet.editSiteContactApi;
      } else if (type === 2) {
        // 运营设置
        api = LocationApiSet.editSiteOprationApi;
      }
      api.params = params;
      this.$http(api).then(res => {
        this.needRefreshList = true;
        this.onChangeDisabled({ disabled: true, msg: '无数据变更' });
        this.$Message.success('操作成功');
        this.onClose();
      });
    }
  }
};
</script>
