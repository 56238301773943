<!--
    Created by 王丽莎  on 2024/11/28.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：设置-运营设置
-->
<style lang="less">
</style>

<template>
  <Form>
    <FormItem
      v-for="(item, index) in formItems"
      :key="index"
      :label="item.label"
      :label-width="item.labelWidth"
      :required="item.isRequired"
    >
      <RadioGroup
        v-if="item.tagName === 'Radio'"
        v-model="model[item.key]"
        :disabled="item.disabled"
        @on-change="checkSubmit"
      >
        <Radio
          v-for="(re, ind) in item.data"
          :key="ind"
          :label="re.value"
        >
          {{ re.label }}
        </Radio>
      </RadioGroup>
    </FormItem>
  </Form>
</template>

<script>

export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      formItems: [
        {
          label: '点位位置',
          labelWidth: 100,
          key: 'sitePosition',
          tagName: 'Radio',
          disabled: false,
          isRequired: true,
          data: [
            {
              label: '地面',
              value: 1
            },
            {
              label: '地下',
              value: 2
            },
            {
              label: '架空层',
              value: 3
            }
          ]
        },
        {
          label: '雨棚状态',
          labelWidth: 100,
          key: 'canopyStatus',
          tagName: 'Radio',
          disabled: false,
          isRequired: true,
          data: [
            {
              label: '未装',
              value: 1
            },
            {
              label: '已装',
              value: 2
            }
          ]
        }
      ]
    };
  },
  created () {
    console.log(this.model);
    this.$nextTick(() => {
      this.checkSubmit();
    });
  },
  methods: {
    checkSubmit () {
      const item = this.formItems.find(v => v.isRequired && !this.model[v.key]);
      this.$emit('on-change', {
        disabled: !!item,
        msg: item ? (item.label + '不可为空') : ''
      });
    }
  }
};
</script>
